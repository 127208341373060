<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query
			:query="query"
			:update="
				data => ({
					mission: data.mission,
					showEmployees: data.company.showBookingEmployees,
				})
			"
			:variables="{ id }"
		>
			<template v-slot="{ result: { loading, data } }">
				<template v-if="data">
					<a-booking-card
						:mission="data.mission"
						:showEmployees="data.showEmployees"
					/>

					<apollo-query
						:query="fabQuery"
						:update="fabData => getIssueTypes(data.mission, fabData)"
						:variables="{ input: { view: 'booking' } }"
					>
						<template v-slot="{ result: { data: booking } }">
							<a-create-issue-buttons
								v-if="booking && isInFuture(data.mission)"
								:issue-types="booking"
								:message="cancelRule"
							/>
						</template>
					</apollo-query>
				</template>

				<a-loading
					v-else
					:loading="loading"
					type="card-heading, list-item@3, divider, list-item-two-line@3"
				/>
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import spacetime from 'spacetime';
import ALoading from '@/components/Loading';
import ABookingCard from '@/components/BookingCard';
import { MISSION, ISSUE_CREATION_TYPES, COMPANY } from '@/graphql';
import missionMixin from '@/mixins/missionMixin';
import ACreateIssueButtons from '@/components/CreateIssueButtons';

export default {
	name: 'Booking',
	components: {
		ALoading,
		ABookingCard,
		ACreateIssueButtons,
	},
	mixins: [missionMixin],
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},
	data: () => ({
		query: MISSION,
		fabQuery: ISSUE_CREATION_TYPES,
		today: spacetime.today('Europe/Stockholm'),
	}),
	apollo: {
		cancelRule: {
			query: COMPANY,
			update: data => data.company.cancelRule,
		},
	},
	methods: {
		getIssueTypes(mission, fabData) {
			const issueTypes = fabData.issueTypes.map(issueType => {
				const subj = issueType.subject.split(' - ')[0];
				const serviceName = mission.services
					.map(service => service.name)
					.join(', ');
				const bookingNumber = mission.workorder.number;
				const date = this.$_missionMixin_missionDate(mission);
				const month = this.$_missionMixin_missionMonth(mission).slice(0, 3);
				issueType.subject = `${subj} - ${serviceName}, ${date} ${month} (${bookingNumber})`;
				return issueType;
			});
			return issueTypes;
		},
		isInFuture(mission) {
			return this.$_missionMixin_isBefore(this.today, this.$_missionMixin_getStartDate(mission));
		},
	},
	metaInfo: {
		title: 'Bokning',
	},
};
</script>

<style></style>
