var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"flex-column flex-nowrap align-self-start"},[_c('apollo-query',{attrs:{"query":_vm.query,"update":function (data) { return ({
				mission: data.mission,
				showEmployees: data.company.showBookingEmployees,
			}); },"variables":{ id: _vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var ref_result = ref.result;
			var loading = ref_result.loading;
			var data = ref_result.data;
return [(data)?[_c('a-booking-card',{attrs:{"mission":data.mission,"showEmployees":data.showEmployees}}),_c('apollo-query',{attrs:{"query":_vm.fabQuery,"update":function (fabData) { return _vm.getIssueTypes(data.mission, fabData); },"variables":{ input: { view: 'booking' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var booking = ref.result.data;
return [(booking && _vm.isInFuture(data.mission))?_c('a-create-issue-buttons',{attrs:{"issue-types":booking,"message":_vm.cancelRule}}):_vm._e()]}}],null,true)})]:_c('a-loading',{attrs:{"loading":loading,"type":"card-heading, list-item@3, divider, list-item-two-line@3"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }