<template>
	<v-container v-if="issueTypes.length > 0" class="pa-0">
		<template v-for="(iType, index) in issueTypes">
			<v-btn
				v-if="iType.text"
				:key="index"
				:color="iType.color"
				@click="
					chosenIssueType = iType;
					overlay = true;
				"
				dark
				block
				class="my-2"
			>
				<v-icon class="pr-2">{{ iType.icon }}</v-icon
				>{{ iType.text }}
			</v-btn>
		</template>

		<v-dialog v-model="overlay" max-width="800px">
			<a-create-issue-card
				:issueType="chosenIssueType"
				@close="overlay = false"
				:title="title"
				:message="message"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import ACreateIssueCard from '@/components/CreateIssueCard';

export default {
	components: {
		ACreateIssueCard,
	},
	props: {
		issueTypes: {
			type: Array,
			default: () => [],
		},
		title: {
			type: Boolean,
		},
		message: {
			type: String,
			default: () => null,
		},
	},
	data: () => ({
		chosenIssueType: {},
		overlay: false,
	}),
};
</script>

<style></style>
