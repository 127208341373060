<template>
	<v-card class="mb-2">
		<v-container class="pa-0 ma-0 white--text primary">
			<v-row class="px-4 pb-0 mt-auto">
				<v-col class="subtitle-1 pr-0 mr-0" cols="3">Bokning</v-col>
			</v-row>

			<v-card-text class="pt-0 title pb-0 white--text">
				{{ getMissionDate() }}
				<br />
				<div class="subtitle-1">{{ getMissionTime() }}</div>
			</v-card-text>
			<v-card-text class="subtitle-1 white--text">
				{{ mission.services.map(service => service.name).join(', ') }}
			</v-card-text>
		</v-container>

		<v-container class="pa-0 ma-0">
			<v-list>
				<template v-if="status === 0">
					<v-alert type="info" outlined border="left" class="mx-3 my-1">
						Ska utföras
					</v-alert>
				</template>
				<template v-else-if="status === 1">
					<v-alert type="info" outlined border="left" class="mx-3 my-1">
						Utförs just nu
					</v-alert>
				</template>
				<template v-else>
					<v-alert type="success" outlined border="left" class="mx-3 my-1">
						Utförd
					</v-alert>
				</template>
			</v-list>
		</v-container>

		<v-container class="pa-0 ma-0">
			<v-list>
				<v-list-item v-if="mission.recurring.recurring" two-line>
					<v-list-item-content>
						<v-list-item-subtitle>Återkommande</v-list-item-subtitle>
						<v-list-item-title>
							{{ mission.recurring.description }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item v-if="employees.length > 0 && showEmployees" two-line>
					<v-list-item-content>
						<v-list-item-subtitle>Personal</v-list-item-subtitle>

						<v-list-item-title
							v-for="employee in employees"
							:key="employee.name"
						>
							{{ employee.name }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item three-line>
					<v-list-item-content>
						<v-list-item-subtitle>Adress</v-list-item-subtitle>

						<v-list-item-title>
							{{ mission.address.address }}
						</v-list-item-title>

						<v-list-item-subtitle>{{ areaCodeAndCity }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<div class="text-center pb-3 pt-3">
					<v-btn :href="calendarUrl" target="_blank" dark color="secondary">
						<v-icon class="pr-2">mdi-calendar-plus</v-icon>Google Calendar
					</v-btn>
				</div>
			</v-list>
		</v-container>
	</v-card>
</template>

<script>
import spacetime from 'spacetime';
import addressMixin from '@/mixins/addressMixin';
import missionMixin from '@/mixins/missionMixin';
const NO_EMPLOYEE_ID = '0'; // ID used by TimeWave to indicate a missing employee ("Utan anställd")

export default {
	name: 'BookingCard',
	mixins: [addressMixin, missionMixin],
	props: {
		mission: {
			type: Object,
			required: true,
		},
		showEmployees: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		status() {
			const employee = this.mission.employees[0];
			const start = spacetime(`${employee.startDate}T${employee.startTime}`);
			const end = spacetime(`${employee.startDate}T${employee.endTime}`);
			const now = spacetime.now();
			if (now.isBefore(start)) {
				return 0;
			}

			if (now.isAfter(start) && now.isBefore(end)) {
				return 1;
			}

			return 2;
		},
		// It is possible that placeholder employees are used ("Utan anställd") by TimeWave,
		// but these should not be displayed for the booking.
		employees() {
			return this.mission.employees.filter(employee => {
				const employeeId = employee.id.split('@')[0];
				return employeeId !== 'null' && employeeId !== NO_EMPLOYEE_ID;
			});
		},
		areaCodeAndCity() {
			return this.$_addressMixin_formatAreaCodeAndCity(
				this.mission.address.areaCode,
				this.mission.address.city,
			);
		},
		calendarUrl() {
			const start = this.$_missionMixin_getStartDate(this.mission);
			const end = this.$_missionMixin_getEndDate(this.mission);
			const title = this.mission.services[0].name;
			const { description } = this.mission.services[0];
			const { address } = this.mission;
			const { address: street, areaCode, city } = address;
			const dates = `${start}00%2F${end}00`.replace(/-/g, '').replace(/:/g, '');
			const location = `${street}, ${areaCode}, ${city}`.replace(/ /g, '%20');
			const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${dates}`;
			return url;
		},
	},
	methods: {
		getMissionDate() {
			const dayName = this.$_missionMixin_missionDayName(this.mission);
			const date = this.$_missionMixin_missionDate(this.mission);
			const month = this.$_missionMixin_missionMonth(this.mission);
			return `${dayName}en den ${date} ${month}`;
		},
		getMissionTime() {
			return this.$_missionMixin_missionTime(this.mission);
		},
	},
};
</script>

<style></style>
